<!-- app.vue-->
<!--
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->
<template>
  <a-config-provider :locale="locale[lang]">
    <div id="app">
      <component :is="layout">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </component>
      <Notification
          ref="appNotification"
          placement="topRight"
          :duration="null"
          :top="30"
          @close="onClose"
      />
      <a-modal
          :title="$t('title.server_expiration')"
          :okText="$t('basics.confirm')"
          :cancelText="$t('basics.cancel')"
          :closable="false"
          :visible="ServerKeyModel"
          :maskClosable="true"
          width="760px"
          @ok="serverKeyModelConfirm"
          @cancel="serverKeyModelCancel"
      >
        <p style="color: #D9D9D9">
          {{ $t("permission.o") }}
        </p>
        <a-input v-model="server_key"/>
      </a-modal>
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import en_US from "ant-design-vue/lib/locale-provider/en_US";
import de_DE from "ant-design-vue/lib/locale-provider/de_DE";
import fr_FR from "ant-design-vue/lib/locale-provider/fr_FR"
import Notification from "@/components/Notification";

export default {
  computed: {
    // Sets components name based on current route's specified layout, defaults to
    // <layout-default></layout-default> component.
    layout() {
      return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },
    lang() {
      return this.$store.getters.getLang
    },
  },
  components: {
    Notification,
  },
  data() {
    return {
      locale: {
        cn: zh_CN,
        en: en_US,
        de: de_DE,
        fr: fr_FR
      },
      server_key: '',
      ServerKeyModel: false,
      // failCount: 0,
    };
  },
  mounted() {
    this.$socket.on("permission_error_u", (data) => {
      // this.$message.error('权限不足')
      this.$nextTick(() => {
        const PermissionTable = {
          // 权限位：1 2 4 8 16 32
          "m": this.$t("permission.m", {'printer_id': data.printer_id}),  // 移动 move
          "p": this.$t("permission.p", {'printer_id': data.printer_id}),  // 打印 print
          "h": this.$t("permission.h", {'printer_id': data.printer_id}),  // 加热 heat
          "f": this.$t("permission.f", {'printer_id': data.printer_id}),  // 文件 file
          "v": this.$t("permission.v", {'printer_id': data.printer_id}),  // 视频 video
          "e": this.$t("permission.e", {'printer_id': data.printer_id}),   // 打印机 equipment
          "a": this.$t("permission.a")
        }
        // this.$refs.appNotification.error("对打印机" + data.printer_id + "的移动操作权限不足！")
        if (data.options !== 'o') {
          this.$refs.appNotification.error(PermissionTable[data.options])
        } else {
          this.ServerKeyModel = true
        }
      })
    });
    this.$socket.on('input_server_key', (data) => {
      if (data.state === 0) {
        this.ServerKeyModel = false
        this.$message.success(this.$t("message.activation_successful").toString())
      } else {
        this.$message.error(this.$t("message.7001").toString())
      }
    });
    this.$axios.get('/auth/').then(res => {
      if (res.data.state === 0) {
        // this.$store.commit('setUserInfo', res.data.data.token)
        this.$socket.auth = {
          role: 'user',
          uid: ' ',
          key: ' ',
          token: res.data.token
        }
        this.$socket.connect()
      } else {
        if (this.$route.path !== '/sign-in/0') {
          this.$router.push('/sign-in/0')
        }
      }
    }).catch(err => {
      if (this.$route.path !== '/sign-in/0') {
        this.$router.push('/sign-in/0')
      }
    });
    this.$socket.on("disconnect", () => {
      this.$nextTick(() => {
        this.$refs.appNotification.error(this.$t("message.login_failed"))
        // if (this.failCount !== 0) {
        //
        // }
        // console.log("disconnect", this.failCount)
        // this.failCount++
      })
    });
    this.$socket.on("login_u", (data) => {
      if (data.state === 0) {
        if (this.$route.path !== '/dashboard') {
          this.$router.push('/dashboard')
        }
        this.$store.dispatch('updateUserInfo', data.userInfo)
      }
    });
    this.$socket.onAny((eventName, ...args) => {
      if (eventName.startsWith("print_status_u") || eventName.startsWith("temp_export")) {
        console.log(`-------On Any Event-----------`);
        // console.log(`Received event: ${eventName}`);
        // console.log(`Event data:${args}`);
        // console.log(`-----------------------------`);
      }
    });
  },
  methods: {
    onClose() { // 点击默认关闭按钮时触发的回调函数
    },
    serverKeyModelConfirm() {
      this.$socket.emit('input_server_key', {
        server_key: this.server_key
      })
    },
    serverKeyModelCancel() {
      this.ServerKeyModel = false
      this.server_key = ''
    },
  },
  watch: {
    userInfo: {
      handler(newVal, oldVal) {
        if (newVal.username === '') {
          if (this.$route.path !== '/sign-in/0') {
            this.$router.push('/sign-in/0')
          }
        }
      },
      deep: true,
      // immediate: true
    }
  },
  beforeDestroy() {
    if (this.userInfo.username !== '') {
      this.$socket.disconnect()
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "NotoSansSC";
  src: url("./fonts/NotoSansSC-VariableFont_wght.ttf");
}

#app {
  font-family: "NotoSansSC", Helvetica, Arial, sans-serif;
}
</style>